import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  title: null,
  type: null,
  color: null
});

export default () => ({
  ...initialCrudState(initialModel),
  type: {}
});
